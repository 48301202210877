import React, { useState, useEffect } from "react";
// import globe from "../Image/globe.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
// import hotel from "../Image/hotel.png";
// import list from "../Image/list.png";
// import hotellist from "../Image/hotellist.png";
// import rating from "../Image/rating.png";
// import coupon from "../Image/coupon.png";
// import blog from "../Image/blog.jpg";
import logo from "../img/logo.png";
import users from "../img/users.png";
import userlist from "../img/userlist.png";
import orderlist from "../img/checklist.png";
import membercard from "../img/member-card.png";
import memberlist from "../img/memberlist.png";
import audiowaves from "../img/audio-waves.png";
import history from "../img/history.png";

import shutdown from "../img/shutdown.png";
import genre from "../img/genre.png";
import beatstyle from "../img/beatstyle.png";
import voucher from "../img/voucher.png";
import promolist from "../img/promolist.png";
import dashboard from "../img/dashboard.png";
import ticketinfo from "../img/ticketinfo.png"
import axios from "axios";

const myScrollbar = {
  width: 400,
  height: 400,
};

const Sidebaar = ({ content }) => {
  const [isCollapsed, setIsCollapsed] = useState(
    localStorage.getItem("isCollapsed") === "true"
  );
  const navigate = useNavigate();

  const handleToggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
    localStorage.setItem("isCollapsed", isCollapsed ? "false" : "true");
  };

  useEffect(() => {
    checkCollapse();
  }, []);

  const checkCollapse = () => {
    if (localStorage.getItem("isCollapsed") === "true") {
      setIsCollapsed(true);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      // Handle specific error codes or situations here

      if (error.response && error.response.status === 403) {
        // Handle 403 error without logging it
        // navigate("/login");

        navigate("/");
      }

      return Promise.reject(error);
    }
  );

  const handlelogout = () => {
    // Clear cookies
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
    });
    localStorage.clear();
  };

  return (
    <div className="w-100 d-flex">
      <div className={`sidebarContainer ${isCollapsed ? "collapsed" : ""}`}>
        <div className="w-100 h-100 position-relative">
          <div className="sidebar-header text-center">
            <a
              href="#"
              className="btn text-dark bg-white"
              id="closeSidebar"
              onClick={handleToggleSidebar}
            >
              {isCollapsed ? (
                <i class="fa-solid fa-xmark fs-5"></i>
              ) : (
                <i class="fa-solid fa-bars fs-5"></i>
              )}
            </a>
            <a href="#" className="d-md-none d-block desktoplogo ">
              <img
                src={logo}
                className="img-fluid mobile_fullname_logo"
                alt=""
              />
            </a>
            <a href="#" className="d-none d-md-block ">
              <img src={logo} alt="" className="img-fluid beat_logo" />
              <img src={logo} className="img-fluid fullname_logo" alt="" />
            </a>
          </div>
          <div className="sidebar-content" id="Sidebar_nav">
            <ul className="list-unstyled">
              {/* <li>
                <NavLink
                  to={"/dash"}
                  className="d-flex  align-items-center gap-2 Sidebar_link"
                >
                  <span className="icon">
                    <img src={users} alt="" className="img-fluid" />
                  </span>
                  <p
                    className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}
                  >
                    Users
                  </p>
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  to={"/dash"}
                  className="d-flex  align-items-center gap-2 Sidebar_link"
                >
                  <span className="icon">
                    <img src={dashboard} alt="" className="img-fluid" />
                  </span>
                  <p
                    className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}
                  >
                    Dashboard
                  </p>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/userlist"}
                  className="d-flex  align-items-center gap-2 Sidebar_link"
                >
                  <span className="icon">
                    <img src={userlist} alt="" className="img-fluid" />
                  </span>
                  <p
                    className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}
                  >
                    Memebers
                  </p>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/forminfo"}
                  className="d-flex  align-items-center gap-2 Sidebar_link"
                >
                  <span className="icon">
                    <img src={ticketinfo} alt="" className="img-fluid" />
                  </span>
                  <p
                    className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}
                  >
                    Booking
                  </p>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/addmembership"}
                  className="d-flex  align-items-center gap-2 Sidebar_link"
                >
                  <span className="icon">
                    <img src={membercard} alt="" className="img-fluid" />
                  </span>
                  <p
                    className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}
                  >
                    Add MemberShip
                  </p>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/memberlist"}
                  className="d-flex  align-items-center gap-2 Sidebar_link"
                >
                  <span className="icon">
                    <img src={memberlist} alt="" className="img-fluid" />
                  </span>
                  <p
                    className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}
                  >
                    MemberShip List
                  </p>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/fetchbeat"}
                  className="d-flex  align-items-center gap-2 Sidebar_link"
                >
                  <span className="icon">
                    <img src={audiowaves} alt="" className="img-fluid" />
                  </span>
                  <p
                    className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}
                  >
                    Beats List
                  </p>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/beats-history"}
                  className="d-flex  align-items-center gap-2 Sidebar_link"
                >
                  <span className="icon">
                    <img src={history} alt="" className="img-fluid" />
                  </span>
                  <p
                    className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}
                  >
                    Beats History
                  </p>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/genrelist"}
                  className="d-flex  align-items-center gap-2 Sidebar_link"
                >
                  <span className="icon">
                    <img src={genre} alt="" className="img-fluid" />
                  </span>
                  <p
                    className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}
                  >
                    Genre
                  </p>
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  to={"/beatstyle"}
                  className="d-flex  align-items-center gap-2 Sidebar_link"
                >
                  <span className="icon">
                    <img src={beatstyle} alt="" className="img-fluid" />
                  </span>
                  <p
                    className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}
                  >
                    Beat Style
                  </p>
                </NavLink>
              </li> */}
              {/* <li>
                <NavLink
                  to={"/addpromo"}
                  className="d-flex  align-items-center gap-2 Sidebar_link"
                >
                  <span className="icon">
                    <img src={voucher} alt="" className="img-fluid" />
                  </span>
                  <p
                    className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}
                  >
                    Promo
                  </p>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/promolist"}
                  className="d-flex  align-items-center gap-2 Sidebar_link"
                >
                  <span className="icon">
                    <img src={promolist} alt="" className="img-fluid" />
                  </span>
                  <p
                    className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}
                  >
                    Promo List
                  </p>
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  to={"/"}
                  className="d-flex  align-items-center gap-2 Sidebar_link"
                  onClick={(e) => handlelogout(e)}
                >
                  <span className="icon">
                    <img src={shutdown} alt="" className="img-fluid" />
                  </span>
                  <p
                    className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}
                  >
                    Logout
                  </p>
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="sidebar-footer ">{/* Sidebar footer */}</div>
        </div>
      </div>
      <div className={`mainContainer ${isCollapsed ? "collapsed" : ""}`}>
        <div className="w-100 h-100">
          <div className="w-100 sticky-top">
            <nav className="w-100 topbarNav nav-bg">
              <div className="container-fluid">
                <div className="row align-items-center">
                  <div className="col-md-12 col-4">
                    <a
                      href="#"
                      className="btn border-0 shadow-none makeExtend bg-white"
                      onClick={handleToggleSidebar}
                    >
                      {isCollapsed ? (
                        <i class="fa-solid fa-xmark fs-5"></i>
                      ) : (
                        <i class="fa-solid fa-bars fs-5"></i>
                      )}
                    </a>
                    <div className="mobilelogo">
                      <a href="#" className="d-md-none d-block">
                        <img
                          src={logo}
                          className="img-fluid mobile_fullname_logo"
                          alt=""
                        />
                      </a>
                      <a href="#" className="d-none d-md-block">
                        <img
                          src={logo}
                          alt=""
                          className="img-fluid beat_logo"
                        />
                        <img
                          src={logo}
                          className="img-fluid fullname_logo"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
            <div className="container-fluid">
              <div className="row">{content}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebaar;
