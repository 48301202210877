import logo from './logo.svg';
import './App.css';
import LoginPage from './Pages/Login';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './Pages/Dashboard';
import UserlistPage from './Pages/UserlistPage';
import OrderlistPage from './Pages/OrderlistPage';
import Addmemebers from './Element/Addmemebers';
import MembershipPage from './Pages/MembershipPage';
import MemberList from './Pages/MemberList';
import Fetchbeats from './Pages/Fetchbeats';
import Genrelist from './Pages/Genrelist';
import BeatStyle from './Pages/BeatStyle';
import Addpromo from './Pages/Addpromo';
import Promolist from './Pages/Promolist';
import { useEffect } from 'react';
import UserDetail from './Pages/UserDetail';
import PrivateRoutes from './Authentication/PrivateRoutes';
import TotalMembership from './Pages/TotalMembership';
import FormDetail from './Pages/FormDetail';
import BeatsHistory from './Pages/BeatsHistory';

function App() {
  return (
    <>

      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path='/dash' element={<Dashboard />} />
            <Route path='/userlist' element={<UserlistPage />} />
            <Route path='/dash/TotalBuyer' element={<UserlistPage />} />
            <Route path='/dash/Totalseller' element={<UserlistPage />} />
            <Route path='/dash/pending' element={<Fetchbeats />} />
            <Route path='/dash/verified' element={<Fetchbeats />} />
            <Route path='/dash/rejected' element={<Fetchbeats />} />
            <Route path='/dash/Totalsale' element={<UserlistPage />} />


            <Route path='/forminfo' element={<FormDetail />} />
            <Route path='/beats-history' element={<BeatsHistory />} />




            <Route path='/orderlist' element={<OrderlistPage />} />
            <Route path='/addmembership' element={<MembershipPage />} />
            <Route path='/dash/Totalmembership' element={<TotalMembership />} />

            <Route path='/editmemebership'>
              <Route path=':id' element={<MembershipPage />} />
            </Route>
            <Route path='/memberlist' element={<MemberList />} />
            <Route path='/fetchbeat' element={<Fetchbeats />} />
            <Route path='/dash/sold' element={<Fetchbeats />} />

            <Route path='/genrelist' element={<Genrelist />} />
            <Route path='/beatstyle' element={<BeatStyle />} />
            <Route path='/addpromo' element={<Addpromo />} />
            <Route path='/promolist' element={<Promolist />} />
            <Route path='/userdetail'>
              <Route path=':id' element={<UserDetail />} />
            </Route>
          </Route>

          <Route path='/' element={<LoginPage />} />

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
