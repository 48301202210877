import React, { useEffect, useState } from "react";
import Sidebaar from "../Component/Sidebaar";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";
import { Button, Card, Col, Modal } from "react-bootstrap";
import TitleComp from "../Component/TitleComp";
import sold from "../img/sold.png";
import verified from "../img/check.png";
import cancel from "../img/cancel.png";
import { RotatingLines } from "react-loader-spinner";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

function Fetchbeats() {
    let userid = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    const [beatdata, setbeatdata] = useState([]);
    const [loading, setLoading] = useState(false);

    const [infomodal, setInfoModal] = useState(false);
    const [modelinfo, setModelInfo] = useState(null);

    const handleInfoClose = () => setInfoModal(false);
    const handleInfoShow = (row) => {
        setModelInfo(row);
        setInfoModal(true);
    };









    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const fetchbeats = () => {
        setLoading(true);
        axios.get(`${BASE_URL}/fetch_invoice`, { headers }).then((res) => {
            setbeatdata(res.data.data);

            if (res.data.message == "Ok") {
                setLoading(false);
            } else {
                setLoading(false)
            }
        });
    };


    useEffect(() => {
        fetchbeats()
    }, [])














    const getRowId = (row) => row._id;

    const columns = [
        {
            field: "User Name", headerName: "User Name", flex: 1, renderCell: (params) => (
                <>
                    {params.row.user?.name}
                </>
            ),
        },
        {
            field: "seller",
            headerName: "Seller",
            flex: 1,
            valueGetter: (params) => params.row.seller?.name || "N/A",
        },





        {
            field: "net_amount",
            headerName: "Net Amount",
            flex: 1,
            renderCell: (params) => (
                <>
                    ₹ {params.value}
                </>
            ),
        },
        {
            field: "net_amount_to_admin",
            headerName: "Net Amount to Admin",
            flex: 1,
            renderCell: (params) => (
                <>
                    ₹ {params.value}
                </>
            ),
        },
        {
            field: "Order Beats",
            headerName: "Order Beats",
            flex: 1,
            renderCell: (params) => (
                <button className="btn" onClick={() => handleInfoShow(params.row)}>
                    <i className="fa-solid fa-eye"></i>
                </button>
            ),
        },


    ];





    return (
        <Sidebaar
            content={
                <>
                    {loading && (
                        <div className="overlay">
                            <RotatingLines
                                strokeColor="#051322"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="96"
                                visible={true}
                            />
                        </div>
                    )}

                    <section className="my-3">
                        <div className="container">
                            <div className="row gy-3 align-items-center">
                                <TitleComp title="Beats List" />


                                <div className="col-md-12">
                                    <Box sx={{ height: 500, width: "100%" }}>
                                        <DataGrid
                                            rows={beatdata
                                            }
                                            columns={columns}
                                            pageSize={5}
                                            getRowId={getRowId}
                                            initialState={{
                                                pagination: { paginationModel: { pageSize: 10 } },
                                            }}
                                            pageSizeOptions={[5, 10, 25]}
                                        />
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </section>





                    <Modal show={infomodal} onHide={handleInfoClose} centered>
                        <Modal.Header >
                            <Modal.Title>
                                <i className="fa-solid fa-music"></i> {modelinfo?.name} Details
                            </Modal.Title>
                            <Button variant="outline-secondary" onClick={handleInfoClose}>
                                <i className="fa-solid fa-times"></i> Close
                            </Button>
                        </Modal.Header>
                        <Modal.Body>
                            {modelinfo?.ordered_beat?.length > 0 ? (
                                <div className="d-flex flex-column gap-3">
                                    {modelinfo.ordered_beat.map((beat, index) => (
                                        <Card key={index} className="shadow-sm border-0">
                                            <Card.Body>
                                                <h5 className="mb-2 text-primary">{beat.name}</h5>
                                                <p className="mb-1"><strong>Price:</strong> ₹{beat.price}</p>
                                                <p className="mb-1">
                                                    <strong>Trending:</strong> {beat.trending === "1" ? "🔥 Trending" : "❄️ Not Trending"}
                                                </p>
                                            </Card.Body>
                                        </Card>
                                    ))}
                                </div>
                            ) : (
                                <div className="text-center p-4">
                                    <i className="fa-solid fa-exclamation-circle text-warning fa-2x"></i>
                                    <p className="mt-2 text-muted">No ordered beats found.</p>
                                </div>
                            )}
                        </Modal.Body>

                    </Modal>



                </>
            }
        />
    );
}

export default Fetchbeats;

