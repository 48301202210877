import React, { useEffect, useState } from "react";
import TitleComp from "../Component/TitleComp";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";
import { Alert, Form } from "react-bootstrap";
import { RotatingLines } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";

function AddMembers() {
  let token = localStorage.getItem("token");
  const [data, setdata] = useState([]);
  const { id } = useParams();
  const [name, setName] = useState("");
  const [price, setPrice] = useState(0); // assuming price is a number


  const [loading, setLoading] = useState(false);

  const [description, setDescription] = useState("");
  const [membershipFor, setMembershipFor] = useState("");
  const [membershipPeriod, setMembershipPeriod] = useState("");
  const [membershipPeriodCount, setMembershipPeriodCount] = useState("");
  const [noOfBeatLimit, setNoOfBeatLimit] = useState("");
  const [buyBeatLimit, setBuyBeatLimit] = useState("");
  const [uploadBeatLimit, setUploadBeatLimit] = useState("");
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);

    // Show preview of the selected image
    if (selectedFile) {
      const previewUrl = URL.createObjectURL(selectedFile);
      setImagePreview(previewUrl);
    }
  };

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const handleget = () => {
    setLoading(true);
    axios.get(`${BASE_URL}/membership?type=All`, { headers }).then((res) => {
      // let resdata = res.data.data[id];
      let resdata = res.data.data.find((obj) => obj._id === id);

      console.log("resdata", resdata);

      setdata(resdata);
      setName(resdata?.heading);
      setPrice(resdata?.price);
      setMembershipFor(resdata?.membership_for);
      setMembershipPeriod(resdata?.membership_period);
      setMembershipPeriodCount(resdata?.membership_period_count);
      setNoOfBeatLimit(resdata?.no_of_beat_limit);
      setBuyBeatLimit(resdata?.buy_beat_limit);
      setUploadBeatLimit(resdata?.upload_beat_limit);
      setDescription(resdata?.description);
      setFile(resdata?.image);
      if (res.status === "OK" || 200) {
        setLoading(false);
      }
      console.log(res);
    });
  };

  useEffect(() => {
    if (id) {
      handleget();
    }
  }, [id]);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let formData = new FormData();
    formData.append("heading", name);
    formData.append("description", description);
    formData.append("membership_for", membershipFor);
    formData.append("membership_period", membershipPeriod);
    formData.append("membership_period_count", membershipPeriodCount);
    formData.append("no_of_beat_limit", noOfBeatLimit);
    formData.append("buy_beat_limit", noOfBeatLimit);
    formData.append("upload_beat_limit", noOfBeatLimit);
    formData.append("price", price);
    formData.append("image", file);

    const requestConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (id) {
      formData.append("_id", data._id);
      axios
        .put(`${BASE_URL}/membership`, formData, requestConfig)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          navigate("/memberlist");
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      // If id doesn't exist, it's a create (POST) operation
      axios
        .post(`${BASE_URL}/membership`, formData, requestConfig)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          if (res.data.message == "Successfully") {
            navigate('/memberlist')
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      {loading && (
        <div className="overlay">
          <RotatingLines
            strokeColor="#051322"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
          />
        </div>
      )}
      <section>
        <div className="container">
          <Form onSubmit={handleSubmit}>
            <div className="row gy-4">
              <div className="my-3">
                <TitleComp title="Add Membership" />
              </div>
              {!id && imagePreview && (
                <div className="col-md-12">
                  <img
                    src={imagePreview || `${BASE_URL}/${file}`}
                    className="img-fluid rounded-2"
                    style={{ height: "100px" }}
                    alt=""
                  />
                </div>
              )}
              {id && (
                <div className="col-md-12">
                  <img
                    src={imagePreview || `${BASE_URL}/${file}`}
                    className="img-fluid rounded-2"
                    style={{ height: "100px" }}
                    alt=""
                  />
                </div>
              )}
              <div className="col-md-4">
                <label htmlFor="">Image</label>
                <input
                  type="file"
                  accept=".jpg , .jpeg ,  .png , .webp,.avif"
                  name="file"
                  className="form-control"
                  onChange={handleFileChange}
                />
              </div>

              <div className="col-md-4">
                <label htmlFor="">Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="">Price</label>
                <input
                  type="number"
                  className="form-control"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
              {/* <div className="col-md-4">
              <label htmlFor="">Heading</label>
              <input
                type="text"
                className="form-control"
                value={heading}
                onChange={(e) => setHeading(e.target.value)}
              />
            </div> */}

              <div className="col-md-4">
                <label htmlFor="">Membership For</label>
                <select
                  className="form-control form-select"
                  value={membershipFor}
                  onChange={(e) => setMembershipFor(e.target.value)}
                >
                  <option value="">Select Membership For</option>
                  <option value="Both">Both</option>
                  <option value="Seller">Seller</option>
                  <option value="Buyer">Buyer</option>
                  {/* Add other membership options here */}
                </select>
              </div>
              <div className="col-md-4">
                <label htmlFor="">Membership Period</label>
                <select
                  className="form-control form-select"
                  value={membershipPeriod}
                  onChange={(e) => setMembershipPeriod(e.target.value)}
                >
                  <option value="">Select Membership Period</option>
                  <option value="Days">Days</option>
                  <option value="Years">Years</option>
                  <option value="Months">Months</option>
                  {/* Add other membership period options here */}
                </select>
              </div>
              <div className="col-md-4">
                <label htmlFor="">Membership Period Count</label>
                <input
                  type="number"
                  className="form-control"
                  value={membershipPeriodCount}
                  onChange={(e) => setMembershipPeriodCount(e.target.value)}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="">Number of Beat Limit</label>
                <input
                  type="number"
                  className="form-control"
                  value={noOfBeatLimit}
                  onChange={(e) => setNoOfBeatLimit(e.target.value)}
                />
              </div>
              {/* <div className="col-md-4">
                <label htmlFor="">Buy Beat Limit</label>
                <input
                  type="number"
                  className="form-control"
                  value={buyBeatLimit}
                  onChange={(e) => setBuyBeatLimit(e.target.value)}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="">Upload Beat Limit</label>
                <input
                  type="number"
                  className="form-control"
                  value={uploadBeatLimit}
                  onChange={(e) => setUploadBeatLimit(e.target.value)}
                />
              </div> */}

              <div className="col-md-12">
                <label htmlFor="">Description</label>
                <textarea
                  className="form-control"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="col-md-12">
                <button className="btn bg-blue text-white">Submit</button>
              </div>
            </div>
          </Form>
        </div>
      </section>
    </>
  );
}

export default AddMembers;
